input {
    font-weight: bold !important;
}
::placeholder {
    font-weight: normal;
    color: #e0e0e0 !important;
}
form .form-fields {
    display: block;
}
#stepper-dtl .btn-wrap.hide,
form .form-fields.hide,
form .form-btns.hide {
    display: none;
}
.form {
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
.form-fields .form:not(:last-of-type) {
    margin-bottom: 30px;
}
.form .field {
    position: relative;
}
form .form-msg {
    padding: 5px;
    background: #fff;
    font-weight: 400;
    font-size: 14px;
    border-radius: 24px;
    margin-top: 15px;
    font-family: "Montserrat", "Arial", "Calibri", sans-serif;
}
form .form-msg.success {
    border: 3px solid #219653;
}
form .form-msg.error {
    border: 3px solid #b11116;
}
form .form-msg.info {
    border: 3px solid #D5B527;
}
form .form-msg p {
    text-align: center;
}
.form .field label {
    margin-bottom: 7px;
    font-family: "Inter", "Arial", "Calibri", sans-serif;
    font-weight: 400;
    line-height: 16px;
    color: #373F41;
    display: inline-block;
    position: relative;
}
.form .field:not(.required) label.rdo-label {
    display: block;
}
.form .field > .field-error {
    color: #b1111f;
    margin-top: 9px;
    line-height: 15px;
    font-family: "Inter", "Arial", "Calibri", sans-serif;
    font-size: 12px;
}
.form .field .tooltip {
    position: absolute;
    right: 0;
    top: 2px;
    white-space: pre-line;
}
.form .field label.rdo-label {
    font-family: "ProximaNova", "Arial", "Calibri", sans-serif;
    font-size: 16px;
}
.form .field.required label::before {
    content:"*";
    padding-left: 5px;
    float: right;
    color: red;
}
.form .field.required mat-radio-group {
    display: block;
}
.form .field.required mat-radio-group label::before {
    display: none;
}
.form .col-case {
    overflow: hidden;
    margin-bottom: 24px;
}
.form .col-case:last-of-type {
    margin-bottom: 0;
}
.form .col-case .field {
    float: left;
    width: 100%;
}
.form .col-2 .field {
    width: 49% !important;
    margin-right: 2%;
}
.form .col-2 .field:nth-child(even) {
    margin-right: 0;
}
.form .col-4 .field {
    max-width: 220px;
    width: 23.31%;
    margin-right: 2.25% ;
}
.form .col-4 .field:nth-child(4n) {
    margin-right: 0;
}
.form .col-3 .field {
    max-width: 300px;
    width: 31.8%;
    margin-right: 2.3%;
}
.form .col-3 .field:nth-child(3n) {
    margin-right: 0;
}
.form-btns {
    margin: 30px 0 42px;
    text-align: right;
}
.form-btns .btn {
    display: inline-block;
    margin: 0 8px;
    min-width: 144px;
    padding: 13px 10px;
    font-family: "ProximaNova", "Arial", "Calibri", sans-serif;
    font-size: 16px;
    font-weight: 600;
}
.form-btns .btn:first-of-type {
    margin-left: 0;
}
.form-btns .btn:last-of-type {
    margin-right: 0;
}
.form-btns .btn4 {
    border: 1px solid #E0E0E0;
}
.form-btns .btn2 {
    border: 1px solid #B11116;
}
.box-wrap {
    margin-top: 50px;
    border-radius: 5px;
    padding: 18px 24px;
    box-shadow: 0px 1px 2px rgba(27, 20, 100, 0.24);
    font-family: "ProximaNova", "Arial", "Calibri", sans-serif;
}
.box-wrap table:last-of-type {
    margin-bottom: 0;
}
.form .box-wrap,
.form table {
    margin: 0 0 20px;
}
.btn-wrap {
    text-align: center;
}
.btn-wrap.right-aligned {
    text-align: right;
}
.btn-wrap.left-aligned {
    text-align: left;
}
.btn-wrap .btn {
    display: inline-block;
    margin: 0 8px;
}
.btn-wrap .btn:first-of-type {
    margin-left: 0;
}
.btn-wrap .btn:last-of-type {
    margin-right: 0;
}
button:disabled {
    background-color: #a9a9a9 !important;
    cursor: not-allowed;
    border: 1px solid #a9a9a9 !important;
    opacity: .6;
}
button:disabled:hover {
    background-color: #a9a9a9 !important;
    border: 1px solid #a9a9a9 !important;
}
.mtn-datepicker > button:disabled {
    background: transparent !important;
    border: none !important;
}

/* table style 01*/
.tb-style01 {
    table-layout: fixed;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin-bottom: 10px;
    letter-spacing: .5px;
}
.tb-style01 th {
    font-weight: normal;
    vertical-align: middle;
    padding: 0 5px 6px 0;
}
.tb-style01 td {
    font-weight: 600;
    font-size: 16px;
    color: #000;
}

/* FORM LOADER */
.form-loader .form-loader-container {
    margin: 50px 0 40px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.form-loader img {
    width: 35px;
    position: absolute;
    top: 20px;
    transition: all ease .3s;
}
.form-loader .loader-logo {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: rotate 3s linear infinite;
}
.form-loader .loader-logo .circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    border: 10px solid transparent;
}
.form-loader .loader-logo .circle::before,
.form-loader .loader-logo .circle::after {
    content: '';
    position: absolute;
    height: 10px;
    width: 10px;
    border-radius: 50%;
}
.form-loader .loader-logo .circle::before {
    top: 2px;
    right: 2px;
}
.form-loader .loader-logo .circle::after {
    top: 2px;
    left: 2px;
}
.form-loader .loader-logo .circle.circle-1 {
    border-top-color: #B11116;
}
.form-loader .loader-logo .circle.circle-1::before,
.form-loader .loader-logo .circle.circle-1::after {
    background-color: #B11116;
}
.form-loader .loader-logo .circle.circle-2 {
    border-top-color: #1B1464;
    transform: rotate(120deg);
}
.form-loader .loader-logo .circle.circle-2::before,
.form-loader .loader-logo .circle.circle-2::after {
    background-color: #1B1464;
}
.form-loader .loader-logo .circle.circle-3 {
    border-top-color: #D5B527;
    transform: rotate(240deg);
}
.form-loader .loader-logo .circle.circle-3::before,
.form-loader .loader-logo .circle.circle-3::after {
    background-color: #D5B527;
}
.form-loader .loader-msg {
    width: 100%;
    margin-top: 40px;
    text-align: center;
    box-sizing: border-box;
    padding: 8px;
    border-radius: 50px;
    letter-spacing: .3px;
    line-height: 22px;
}
@keyframes rotate {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 880px) {
    .form-btns .btn {
        min-width: 120px;
    }
}

@media screen and (max-width: 830px) {
    .form-btns {
        display: flex;
        justify-content: center;
    }
    .form-btns .btn {
        min-width: 144px;
    }
}

@media screen and (max-width: 767px) {
    .box-wrap {
        margin-top: 25px;
        padding: 12px 15px;
        overflow-x: scroll;
    }
    .tb-style01 {
        min-width: 600px;
    }
    .tb-style01 th,
    .tb-style01 td {
        font-size: 120%;
    }
    .form .col-4 .field,
    .form .col-3 .field {
        width: 49%;
        max-width: none;
        margin-right: 2%;
    }
    .form .col-3 .field:not(:last-of-type):nth-child(even) {
        margin-bottom: 24px;
    }
    .form .col-4 .field:nth-child(even),
    .form .col-3 .field:nth-child(even) {
        margin-right: 0;
    }
    .form .col-3 .field:nth-child(3n):nth-child(odd) {
        margin-right: 2%;
    }
    .form .col-3 .field:last-child:nth-child(odd) {
        width: 100%;
    }
    .form-btns {
        text-align: center;
    }
}

@media screen and (max-width: 640px) {
    .form-btns .btn {
        min-width: 120px;
        padding: 11px 11px;
        font-size: 15px;
    }
}

@media screen and (max-width: 480px) {
    .form {
        padding-bottom: 25px;
        margin-bottom: 25px;
    }
    .form .col-case {
        margin-bottom: 3%;
    }
    .form .col-2 .field,
    .form .col-4 .field,
    .form .col-3 .field {
        width: 100%;
        max-width: none;
        margin: 0 0 3% !important;
    }
    .form .col-case .field:last-of-type {
        margin-bottom: 0 !important;
    }
    mat-radio-group {
        margin-bottom: 15px;
    }
    .mat-radio-button:not(:first-of-type) {
        margin-top: 17px;
    }
}

@media screen and (max-width: 471px) {
    .form-btns .btn {
        min-width: 105px;
        margin: 0 7px;
    }
}

@media screen and (max-width: 425px) {
    .form-btns .btn {
        min-width: 100px;
        margin: 0 6px;
        font-size: 14px;
    }
}

@media screen and (max-width: 415px) {
    .form-btns .btn {
        min-width: 93px;
        padding: 10px 9px;
    }
}