.spinner {
    width: 70px;
    height: 70px;
    position: relative;
}
.spinner div {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 7px solid transparent;
    border-radius: 50%;
    animation: spin 1.3s linear infinite;
}
.spinner div:nth-of-type(1) {
    border-top-color: #1b1464;
}
.spinner div:nth-of-type(2) {
    border-bottom-color: #1b1464;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}