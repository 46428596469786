html {
	overflow-y: scroll;
}
body {
	margin: 0;
	padding: 0;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}
body p {
    display: block;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-margin-start: 0;
    -webkit-margin-end: 0;
}

/* IE8 */
html>/**/body a:hover img {
    display /*\**/:inline-block\9;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input, textarea, p, blockquote, th, td {
	margin: 0;
	padding: 0;
}
address, caption, cite, code, dfn, em, strong, th, var {
	font-style: normal;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
caption, th, p {
	text-align: left;
}
h1, h2, h3, h4, h5, h6 {
	font-size: 100%;
	text-align: left; 
	font-weight: normal;
}
img, abbr, acronym, fieldset {
	border: 0;
}
li {
	list-style-type: none;
}
span { 
	margin: 0; 
	padding: 0;
}
* { 
	margin: 0;
	padding: 0;
}
dl { 
	margin: 0; 
	padding: 0;
}
img {
    margin: 0;
    padding: 0;
    border: 0;
    max-width: 100%;
    height: auto;
    width: auto;
    vertical-align: middle;
}
a:focus {
    outline: none;
}
a {
    color: #6d46ff;
    text-decoration: none;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
/* a:hover, a:active {
    text-decoration: underline;
} */
/* a:hover img {
    filter: alpha(opacity=80);
    -ms-filter: alpha(opacity=80);
    opacity: 0.8;
} */