/* You can add global styles to this file, and also import other style files */

/* FONTS */

@import './assets/css/fonts.css';

/* THIRD PARTY */

/* GLOBAL */

@import './assets/css/reset.css';
@import './assets/css/common.css';
@import './assets/css/material.css';
@import './assets/css/modified-material.css';
@import './assets/css/form.css';
@import './assets/css/prime-ng.css';
@import './assets/css/spinner.css';
@import './app/components/common/loading/loading.component.css';

/* COMMON */

@import './app/components/common/app-message/app-message.css';
@import './app/components/common/message-box/message-box.css';
@import './app/components/maintenance/maintenance.css';

/* FORMS */


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.tooltip { white-space: pre-line; }
