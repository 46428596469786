.mat-form-field-appearance-fill .mat-form-field-flex {
    border-radius: 4px 4px 0 0;
    padding: .75em .75em 0 .75em
}

.cdk-high-contrast-active .mat-form-field-appearance-fill .mat-form-field-flex {
    outline: solid 1px
}

.mat-form-field-appearance-fill .mat-form-field-underline::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    height: 1px;
    width: 100%
}

.mat-form-field-appearance-fill .mat-form-field-ripple {
    bottom: 0;
    height: 2px
}

.cdk-high-contrast-active .mat-form-field-appearance-fill .mat-form-field-ripple {
    height: 0;
    border-top: solid 2px
}

.mat-form-field-appearance-fill:not(.mat-form-field-disabled) .mat-form-field-flex:hover~.mat-form-field-underline .mat-form-field-ripple {
    opacity: 1;
    transform: none;
    transition: opacity 600ms cubic-bezier(0.25, 0.8, 0.25, 1)
}

.mat-form-field-appearance-fill._mat-animation-noopable:not(.mat-form-field-disabled) .mat-form-field-flex:hover~.mat-form-field-underline .mat-form-field-ripple {
    transition: none
}

.mat-form-field-appearance-fill .mat-form-field-subscript-wrapper {
    padding: 0 1em
}

.mat-input-element {
    font: inherit;
    background: transparent;
    color: currentColor;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: 100%;
    vertical-align: bottom;
    text-align: inherit
}

.mat-input-element:-moz-ui-invalid {
    box-shadow: none
}

.mat-input-element::-ms-clear,
.mat-input-element::-ms-reveal {
    display: none
}

.mat-input-element,
.mat-input-element::-webkit-search-cancel-button,
.mat-input-element::-webkit-search-decoration,
.mat-input-element::-webkit-search-results-button,
.mat-input-element::-webkit-search-results-decoration {
    -webkit-appearance: none
}

.mat-input-element::-webkit-contacts-auto-fill-button,
.mat-input-element::-webkit-caps-lock-indicator,
.mat-input-element::-webkit-credentials-auto-fill-button {
    visibility: hidden
}

.mat-input-element[type=date],
.mat-input-element[type=datetime],
.mat-input-element[type=datetime-local],
.mat-input-element[type=month],
.mat-input-element[type=week],
.mat-input-element[type=time] {
    line-height: 1
}

.mat-input-element[type=date]::after,
.mat-input-element[type=datetime]::after,
.mat-input-element[type=datetime-local]::after,
.mat-input-element[type=month]::after,
.mat-input-element[type=week]::after,
.mat-input-element[type=time]::after {
    content: " ";
    white-space: pre;
    width: 1px
}

.mat-input-element::-webkit-inner-spin-button,
.mat-input-element::-webkit-calendar-picker-indicator,
.mat-input-element::-webkit-clear-button {
    font-size: .75em
}

.mat-input-element::placeholder {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: color 400ms 133.3333333333ms cubic-bezier(0.25, 0.8, 0.25, 1)
}

.mat-input-element::placeholder:-ms-input-placeholder {
    -ms-user-select: text
}

.mat-input-element::-moz-placeholder {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: color 400ms 133.3333333333ms cubic-bezier(0.25, 0.8, 0.25, 1)
}

.mat-input-element::-moz-placeholder:-ms-input-placeholder {
    -ms-user-select: text
}

.mat-input-element::-webkit-input-placeholder {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: color 400ms 133.3333333333ms cubic-bezier(0.25, 0.8, 0.25, 1)
}

.mat-input-element::-webkit-input-placeholder:-ms-input-placeholder {
    -ms-user-select: text
}

.mat-input-element:-ms-input-placeholder {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: color 400ms 133.3333333333ms cubic-bezier(0.25, 0.8, 0.25, 1)
}

.mat-input-element:-ms-input-placeholder:-ms-input-placeholder {
    -ms-user-select: text
}

.mat-form-field-hide-placeholder .mat-input-element::placeholder {
    color: transparent !important;
    -webkit-text-fill-color: transparent;
    transition: none
}

.mat-form-field-hide-placeholder .mat-input-element::-moz-placeholder {
    color: transparent !important;
    -webkit-text-fill-color: transparent;
    transition: none
}

.mat-form-field-hide-placeholder .mat-input-element::-webkit-input-placeholder {
    color: transparent !important;
    -webkit-text-fill-color: transparent;
    transition: none
}

.mat-form-field-hide-placeholder .mat-input-element:-ms-input-placeholder {
    color: transparent !important;
    -webkit-text-fill-color: transparent;
    transition: none
}

textarea.mat-input-element {
    resize: vertical;
    overflow: auto
}

textarea.mat-input-element.cdk-textarea-autosize {
    resize: none
}

textarea.mat-input-element {
    padding: 2px 0;
    margin: -2px 0
}

select.mat-input-element {
    -moz-appearance: none;
    -webkit-appearance: none;
    position: relative;
    background-color: transparent;
    display: inline-flex;
    box-sizing: border-box;
    padding-top: 1em;
    top: -1em;
    margin-bottom: -1em
}

select.mat-input-element::-ms-expand {
    display: none
}

select.mat-input-element::-moz-focus-inner {
    border: 0
}

select.mat-input-element:not(:disabled) {
    cursor: pointer
}

select.mat-input-element::-ms-value {
    color: inherit;
    background: none
}

.mat-focused .cdk-high-contrast-active select.mat-input-element::-ms-value {
    color: inherit
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -2.5px;
    pointer-events: none
}

[dir=rtl] .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
    right: auto;
    left: 0
}

.mat-form-field-type-mat-native-select .mat-input-element {
    padding-right: 15px
}

[dir=rtl] .mat-form-field-type-mat-native-select .mat-input-element {
    padding-right: 0;
    padding-left: 15px
}

.mat-form-field-type-mat-native-select .mat-form-field-label-wrapper {
    max-width: calc(100% - 10px)
}

.mat-form-field-type-mat-native-select.mat-form-field-appearance-outline .mat-form-field-infix::after {
    margin-top: -5px
}

.mat-form-field-type-mat-native-select.mat-form-field-appearance-fill .mat-form-field-infix::after {
    margin-top: -10px
}

.mat-form-field-appearance-legacy .mat-form-field-label {
    transform: perspective(100px);
    -ms-transform: none
}

.mat-form-field-appearance-legacy .mat-form-field-prefix .mat-icon,
.mat-form-field-appearance-legacy .mat-form-field-suffix .mat-icon {
    width: 1em
}

.mat-form-field-appearance-legacy .mat-form-field-prefix .mat-icon-button,
.mat-form-field-appearance-legacy .mat-form-field-suffix .mat-icon-button {
    font: inherit;
    vertical-align: baseline
}

.mat-form-field-appearance-legacy .mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-appearance-legacy .mat-form-field-suffix .mat-icon-button .mat-icon {
    font-size: inherit
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
    height: 1px
}

.cdk-high-contrast-active .mat-form-field-appearance-legacy .mat-form-field-underline {
    height: 0;
    border-top: solid 1px
}

.mat-form-field-appearance-legacy .mat-form-field-ripple {
    top: 0;
    height: 2px;
    overflow: hidden
}

.cdk-high-contrast-active .mat-form-field-appearance-legacy .mat-form-field-ripple {
    height: 0;
    border-top: solid 2px
}

.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
    background-position: 0;
    background-color: transparent
}

.cdk-high-contrast-active .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
    border-top-style: dotted;
    border-top-width: 2px
}

.mat-form-field-appearance-legacy.mat-form-field-invalid:not(.mat-focused) .mat-form-field-ripple {
    height: 1px
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: .25em 0
}

.mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0 .75em 0 .75em;
    margin-top: -0.25em;
    position: relative
}

.mat-form-field-appearance-outline .mat-form-field-prefix,
.mat-form-field-appearance-outline .mat-form-field-suffix {
    top: .25em
}

.mat-form-field-appearance-outline .mat-form-field-outline {
    display: flex;
    position: absolute;
    top: .25em;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none
}

.mat-form-field-appearance-outline .mat-form-field-outline-start,
.mat-form-field-appearance-outline .mat-form-field-outline-end {
    border: 1px solid currentColor;
    min-width: 5px
}

.mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-radius: 5px 0 0 5px;
    border-right-style: none
}

[dir=rtl] .mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-right-style: solid;
    border-left-style: none;
    border-radius: 0 5px 5px 0
}

.mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-radius: 0 5px 5px 0;
    border-left-style: none;
    flex-grow: 1
}

[dir=rtl] .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-left-style: solid;
    border-right-style: none;
    border-radius: 5px 0 0 5px
}

.mat-form-field-appearance-outline .mat-form-field-outline-gap {
    border-radius: .000001px;
    border: 1px solid currentColor;
    border-left-style: none;
    border-right-style: none
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap {
    border-top-color: transparent
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
    opacity: 0
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap {
    border-width: 2px
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline,
.mat-form-field-appearance-outline.mat-form-field-invalid .mat-form-field-outline {
    opacity: 0;
    transition: opacity 100ms cubic-bezier(0.25, 0.8, 0.25, 1)
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick,
.mat-form-field-appearance-outline.mat-form-field-invalid .mat-form-field-outline-thick {
    opacity: 1
}

.mat-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-form-field-flex:hover .mat-form-field-outline {
    opacity: 0;
    transition: opacity 600ms cubic-bezier(0.25, 0.8, 0.25, 1)
}

.mat-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-form-field-flex:hover .mat-form-field-outline-thick {
    opacity: 1
}

.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
    padding: 0 1em
}

.mat-form-field-appearance-outline._mat-animation-noopable:not(.mat-form-field-disabled) .mat-form-field-flex:hover~.mat-form-field-outline,
.mat-form-field-appearance-outline._mat-animation-noopable .mat-form-field-outline,
.mat-form-field-appearance-outline._mat-animation-noopable .mat-form-field-outline-start,
.mat-form-field-appearance-outline._mat-animation-noopable .mat-form-field-outline-end,
.mat-form-field-appearance-outline._mat-animation-noopable .mat-form-field-outline-gap {
    transition: none
}

.mat-form-field-appearance-standard .mat-form-field-flex {
    padding-top: .75em
}

.mat-form-field-appearance-standard .mat-form-field-underline {
    height: 1px
}

.cdk-high-contrast-active .mat-form-field-appearance-standard .mat-form-field-underline {
    height: 0;
    border-top: solid 1px
}

.mat-form-field-appearance-standard .mat-form-field-ripple {
    bottom: 0;
    height: 2px
}

.cdk-high-contrast-active .mat-form-field-appearance-standard .mat-form-field-ripple {
    height: 0;
    border-top: 2px
}

.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
    background-position: 0;
    background-color: transparent
}

.cdk-high-contrast-active .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
    border-top-style: dotted;
    border-top-width: 2px
}

.mat-form-field-appearance-standard:not(.mat-form-field-disabled) .mat-form-field-flex:hover~.mat-form-field-underline .mat-form-field-ripple {
    opacity: 1;
    transform: none;
    transition: opacity 600ms cubic-bezier(0.25, 0.8, 0.25, 1)
}

.mat-form-field-appearance-standard._mat-animation-noopable:not(.mat-form-field-disabled) .mat-form-field-flex:hover~.mat-form-field-underline .mat-form-field-ripple {
    transition: none
}

.mat-select {
    display: inline-block;
    width: 100%;
    outline: none
}

.mat-select-trigger {
    display: inline-table;
    cursor: pointer;
    position: relative;
    box-sizing: border-box
}

.mat-select-disabled .mat-select-trigger {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default
}

.mat-select-value {
    display: table-cell;
    max-width: 0;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.mat-select-value-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.mat-select-arrow-wrapper {
    display: table-cell;
    vertical-align: middle
}

.mat-form-field-appearance-fill .mat-select-arrow-wrapper {
    transform: translateY(-50%)
}

.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    transform: translateY(-25%)
}

.mat-form-field-appearance-standard.mat-form-field-has-label .mat-select:not(.mat-select-empty) .mat-select-arrow-wrapper {
    transform: translateY(-50%)
}

.mat-form-field-appearance-standard .mat-select.mat-select-empty .mat-select-arrow-wrapper {
    transition: transform 400ms cubic-bezier(0.25, 0.8, 0.25, 1)
}

._mat-animation-noopable.mat-form-field-appearance-standard .mat-select.mat-select-empty .mat-select-arrow-wrapper {
    transition: none
}

.mat-select-arrow {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid;
    margin: 0 4px
}

.mat-select-panel-wrap {
    flex-basis: 100%
}

.mat-select-panel {
    min-width: 112px;
    max-width: 280px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding-top: 0;
    padding-bottom: 0;
    max-height: 256px;
    min-width: 100%;
    border-radius: 4px;
    outline: 0
}

.cdk-high-contrast-active .mat-select-panel {
    outline: solid 1px
}

.mat-select-panel .mat-optgroup-label,
.mat-select-panel .mat-option {
    font-size: inherit;
    line-height: 3em;
    height: 3em
}

.mat-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-form-field-flex {
    cursor: pointer
}

.mat-form-field-type-mat-select .mat-form-field-label {
    width: calc(100% - 18px)
}

.mat-select-placeholder {
    transition: color 400ms 133.3333333333ms cubic-bezier(0.25, 0.8, 0.25, 1)
}

._mat-animation-noopable .mat-select-placeholder {
    transition: none
}

.mat-form-field-hide-placeholder .mat-select-placeholder {
    color: transparent;
    -webkit-text-fill-color: transparent;
    transition: none;
    display: block
}

.mat-option {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    line-height: 48px;
    height: 48px;
    padding: 0 16px;
    text-align: left;
    text-decoration: none;
    max-width: 100%;
    position: relative;
    cursor: pointer;
    outline: none;
    display: flex;
    flex-direction: row;
    max-width: 100%;
    box-sizing: border-box;
    align-items: center;
    -webkit-tap-highlight-color: transparent
}

.mat-option[disabled] {
    cursor: default
}

[dir=rtl] .mat-option {
    text-align: right
}

.mat-option .mat-icon {
    margin-right: 16px;
    vertical-align: middle
}

.mat-option .mat-icon svg {
    vertical-align: top
}

[dir=rtl] .mat-option .mat-icon {
    margin-left: 16px;
    margin-right: 0
}

.mat-option[aria-disabled=true] {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default
}

.mat-optgroup .mat-option:not(.mat-option-multiple) {
    padding-left: 32px
}

[dir=rtl] .mat-optgroup .mat-option:not(.mat-option-multiple) {
    padding-left: 16px;
    padding-right: 32px
}

.cdk-high-contrast-active .mat-option {
    margin: 0 1px
}

.cdk-high-contrast-active .mat-option.mat-active {
    border: solid 1px currentColor;
    margin: 0
}

.cdk-high-contrast-active .mat-option[aria-disabled=true] {
    opacity: .5
}

.mat-option-text {
    display: inline-block;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis
}

.mat-option .mat-option-ripple {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    pointer-events: none
}

.mat-option-pseudo-checkbox {
    margin-right: 8px
}

[dir=rtl] .mat-option-pseudo-checkbox {
    margin-left: 8px;
    margin-right: 0
}

.mat-radio-button {
    display: inline-block;
    -webkit-tap-highlight-color: transparent;
    outline: 0
}

.mat-radio-label {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    vertical-align: middle;
    width: 100%
}

.mat-radio-container {
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    flex-shrink: 0
}

.mat-radio-outer-circle {
    box-sizing: border-box;
    display: block;
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;
    transition: border-color ease 280ms;
    width: 20px;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%
}

._mat-animation-noopable .mat-radio-outer-circle {
    transition: none
}

.mat-radio-inner-circle {
    border-radius: 50%;
    box-sizing: border-box;
    display: block;
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;
    transition: transform ease 280ms, background-color ease 280ms;
    width: 20px;
    transform: scale(0.001)
}

._mat-animation-noopable .mat-radio-inner-circle {
    transition: none
}

.mat-radio-checked .mat-radio-inner-circle {
    transform: scale(0.5)
}

.cdk-high-contrast-active .mat-radio-checked .mat-radio-inner-circle {
    border: solid 10px
}

.mat-radio-label-content {
    -webkit-user-select: auto;
    -moz-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
    display: inline-block;
    order: 0;
    line-height: inherit;
    padding-left: 8px;
    padding-right: 0
}

[dir=rtl] .mat-radio-label-content {
    padding-right: 8px;
    padding-left: 0
}

.mat-radio-label-content.mat-radio-label-before {
    order: -1;
    padding-left: 0;
    padding-right: 8px
}

[dir=rtl] .mat-radio-label-content.mat-radio-label-before {
    padding-right: 0;
    padding-left: 8px
}

.mat-radio-disabled,
.mat-radio-disabled .mat-radio-label {
    cursor: default
}

.mat-radio-button .mat-radio-ripple {
    position: absolute;
    left: calc(50% - 20px);
    top: calc(50% - 20px);
    height: 40px;
    width: 40px;
    z-index: 1;
    pointer-events: none
}

.mat-radio-button .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple) {
    opacity: .16
}

.mat-radio-persistent-ripple {
    width: 100%;
    height: 100%;
    transform: none
}

.mat-radio-container:hover .mat-radio-persistent-ripple {
    opacity: .04
}

.mat-radio-button:not(.mat-radio-disabled).cdk-keyboard-focused .mat-radio-persistent-ripple,
.mat-radio-button:not(.mat-radio-disabled).cdk-program-focused .mat-radio-persistent-ripple {
    opacity: .12
}

.mat-radio-persistent-ripple,
.mat-radio-disabled .mat-radio-container:hover .mat-radio-persistent-ripple {
    opacity: 0
}

@media(hover: none) {
    .mat-radio-container:hover .mat-radio-persistent-ripple {
        display: none
    }
}

.mat-radio-input {
    bottom: 0;
    left: 50%
}

.cdk-high-contrast-active .mat-radio-disabled {
    opacity: .5
}

.mat-icon {
    background-repeat: no-repeat;
    display: inline-block;
    fill: currentColor;
    height: 24px;
    width: 24px
}

.mat-icon.mat-icon-inline {
    font-size: inherit;
    height: inherit;
    line-height: inherit;
    width: inherit
}

[dir=rtl] .mat-icon-rtl-mirror {
    transform: scale(-1, 1)
}

.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-icon,
.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon {
    display: block
}

.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button .mat-icon {
    margin: auto
}

.mat-form-field-appearance-legacy .mat-form-field-prefix .mat-datepicker-toggle-default-icon,
.mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
    width: 1em
}

.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-datepicker-toggle-default-icon,
.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
    display: block;
    width: 1.5em;
    height: 1.5em
}

.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-icon-button .mat-datepicker-toggle-default-icon,
.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button .mat-datepicker-toggle-default-icon {
    margin: auto
}

mat-table {
    display: block
}

mat-header-row {
    min-height: 56px
}

mat-row,
mat-footer-row {
    min-height: 48px
}

mat-row,
mat-header-row,
mat-footer-row {
    display: flex;
    border-width: 0;
    border-bottom-width: 1px;
    border-style: solid;
    align-items: center;
    box-sizing: border-box
}

mat-row::after,
mat-header-row::after,
mat-footer-row::after {
    display: inline-block;
    min-height: inherit;
    content: ""
}

mat-cell:first-of-type,
mat-header-cell:first-of-type,
mat-footer-cell:first-of-type {
    padding-left: 24px
}

[dir=rtl] mat-cell:first-of-type:not(:only-of-type),
[dir=rtl] mat-header-cell:first-of-type:not(:only-of-type),
[dir=rtl] mat-footer-cell:first-of-type:not(:only-of-type) {
    padding-left: 0;
    padding-right: 24px
}

mat-cell:last-of-type,
mat-header-cell:last-of-type,
mat-footer-cell:last-of-type {
    padding-right: 24px
}

[dir=rtl] mat-cell:last-of-type:not(:only-of-type),
[dir=rtl] mat-header-cell:last-of-type:not(:only-of-type),
[dir=rtl] mat-footer-cell:last-of-type:not(:only-of-type) {
    padding-right: 0;
    padding-left: 24px
}

mat-cell,
mat-header-cell,
mat-footer-cell {
    flex: 1;
    display: flex;
    align-items: center;
    overflow: hidden;
    word-wrap: break-word;
    min-height: inherit
}

table.mat-table {
    border-spacing: 0
}

tr.mat-header-row {
    height: 56px
}

tr.mat-row,
tr.mat-footer-row {
    height: 48px
}

th.mat-header-cell {
    text-align: left
}

[dir=rtl] th.mat-header-cell {
    text-align: right
}

th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
    padding: 0;
    border-bottom-width: 1px;
    border-bottom-style: solid
}

th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
    padding-left: 24px
}

[dir=rtl] th.mat-header-cell:first-of-type:not(:only-of-type),
[dir=rtl] td.mat-cell:first-of-type:not(:only-of-type),
[dir=rtl] td.mat-footer-cell:first-of-type:not(:only-of-type) {
    padding-left: 0;
    padding-right: 24px
}

th.mat-header-cell:last-of-type,
td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type {
    padding-right: 24px
}

[dir=rtl] th.mat-header-cell:last-of-type:not(:only-of-type),
[dir=rtl] td.mat-cell:last-of-type:not(:only-of-type),
[dir=rtl] td.mat-footer-cell:last-of-type:not(:only-of-type) {
    padding-right: 0;
    padding-left: 24px
}

.mat-table-sticky {
    position: -webkit-sticky !important;
    position: sticky !important
}

.mat-table-fixed-layout {
    table-layout: fixed
}

.mat-button .mat-button-focus-overlay,
.mat-icon-button .mat-button-focus-overlay {
    opacity: 0
}

.mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay,
.mat-stroked-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay {
    opacity: .04
}

@media(hover: none) {
    .mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay,
    .mat-stroked-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay {
        opacity: 0
    }
}

.mat-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button {
    box-sizing: border-box;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    outline: none;
    border: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: 64px;
    line-height: 36px;
    padding: 0 16px;
    border-radius: 4px;
    overflow: visible
}

.mat-button::-moz-focus-inner,
.mat-icon-button::-moz-focus-inner,
.mat-stroked-button::-moz-focus-inner,
.mat-flat-button::-moz-focus-inner {
    border: 0
}

.mat-button.mat-button-disabled,
.mat-icon-button.mat-button-disabled,
.mat-stroked-button.mat-button-disabled,
.mat-flat-button.mat-button-disabled {
    cursor: default
}

.mat-button.cdk-keyboard-focused .mat-button-focus-overlay,
.mat-button.cdk-program-focused .mat-button-focus-overlay,
.mat-icon-button.cdk-keyboard-focused .mat-button-focus-overlay,
.mat-icon-button.cdk-program-focused .mat-button-focus-overlay,
.mat-stroked-button.cdk-keyboard-focused .mat-button-focus-overlay,
.mat-stroked-button.cdk-program-focused .mat-button-focus-overlay,
.mat-flat-button.cdk-keyboard-focused .mat-button-focus-overlay,
.mat-flat-button.cdk-program-focused .mat-button-focus-overlay {
    opacity: .12
}

.mat-button::-moz-focus-inner,
.mat-icon-button::-moz-focus-inner,
.mat-stroked-button::-moz-focus-inner,
.mat-flat-button::-moz-focus-inner {
    border: 0
}

.mat-raised-button {
    box-sizing: border-box;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    outline: none;
    border: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: 64px;
    line-height: 36px;
    padding: 0 16px;
    border-radius: 4px;
    overflow: visible;
    transform: translate3d(0, 0, 0);
    transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1)
}

.mat-raised-button::-moz-focus-inner {
    border: 0
}

.mat-raised-button.mat-button-disabled {
    cursor: default
}

.mat-raised-button.cdk-keyboard-focused .mat-button-focus-overlay,
.mat-raised-button.cdk-program-focused .mat-button-focus-overlay {
    opacity: .12
}

.mat-raised-button::-moz-focus-inner {
    border: 0
}

._mat-animation-noopable.mat-raised-button {
    transition: none;
    animation: none
}

.mat-stroked-button {
    border: 1px solid currentColor;
    padding: 0 15px;
    line-height: 34px
}

.mat-stroked-button .mat-button-ripple.mat-ripple,
.mat-stroked-button .mat-button-focus-overlay {
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px
}

.mat-fab {
    box-sizing: border-box;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    outline: none;
    border: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: 64px;
    line-height: 36px;
    padding: 0 16px;
    border-radius: 4px;
    overflow: visible;
    transform: translate3d(0, 0, 0);
    transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    min-width: 0;
    border-radius: 50%;
    width: 56px;
    height: 56px;
    padding: 0;
    flex-shrink: 0
}

.mat-fab::-moz-focus-inner {
    border: 0
}

.mat-fab.mat-button-disabled {
    cursor: default
}

.mat-fab.cdk-keyboard-focused .mat-button-focus-overlay,
.mat-fab.cdk-program-focused .mat-button-focus-overlay {
    opacity: .12
}

.mat-fab::-moz-focus-inner {
    border: 0
}

._mat-animation-noopable.mat-fab {
    transition: none;
    animation: none
}

.mat-fab .mat-button-wrapper {
    padding: 16px 0;
    display: inline-block;
    line-height: 24px
}

.mat-mini-fab {
    box-sizing: border-box;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    outline: none;
    border: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: 64px;
    line-height: 36px;
    padding: 0 16px;
    border-radius: 4px;
    overflow: visible;
    transform: translate3d(0, 0, 0);
    transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    min-width: 0;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 0;
    flex-shrink: 0
}

.mat-mini-fab::-moz-focus-inner {
    border: 0
}

.mat-mini-fab.mat-button-disabled {
    cursor: default
}

.mat-mini-fab.cdk-keyboard-focused .mat-button-focus-overlay,
.mat-mini-fab.cdk-program-focused .mat-button-focus-overlay {
    opacity: .12
}

.mat-mini-fab::-moz-focus-inner {
    border: 0
}

._mat-animation-noopable.mat-mini-fab {
    transition: none;
    animation: none
}

.mat-mini-fab .mat-button-wrapper {
    padding: 8px 0;
    display: inline-block;
    line-height: 24px
}

.mat-icon-button {
    padding: 0;
    min-width: 0;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    line-height: 40px;
    border-radius: 50%
}

.mat-icon-button i,
.mat-icon-button .mat-icon {
    line-height: 24px
}

.mat-button-ripple.mat-ripple,
.mat-button-focus-overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    pointer-events: none;
    border-radius: inherit
}

.mat-button-ripple.mat-ripple:not(:empty) {
    transform: translateZ(0)
}

.mat-button-focus-overlay {
    opacity: 0;
    transition: opacity 200ms cubic-bezier(0.35, 0, 0.25, 1), background-color 200ms cubic-bezier(0.35, 0, 0.25, 1)
}

._mat-animation-noopable .mat-button-focus-overlay {
    transition: none
}

.mat-button-ripple-round {
    border-radius: 50%;
    z-index: 1
}

.mat-button .mat-button-wrapper>*,
.mat-flat-button .mat-button-wrapper>*,
.mat-stroked-button .mat-button-wrapper>*,
.mat-raised-button .mat-button-wrapper>*,
.mat-icon-button .mat-button-wrapper>*,
.mat-fab .mat-button-wrapper>*,
.mat-mini-fab .mat-button-wrapper>* {
    vertical-align: middle
}

.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-icon-button,
.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: inherit;
    width: 2.5em;
    height: 2.5em
}

.cdk-high-contrast-active .mat-button,
.cdk-high-contrast-active .mat-flat-button,
.cdk-high-contrast-active .mat-raised-button,
.cdk-high-contrast-active .mat-icon-button,
.cdk-high-contrast-active .mat-fab,
.cdk-high-contrast-active .mat-mini-fab {
    outline: solid 1px
}

.cdk-high-contrast-active .mat-button-base.cdk-keyboard-focused,
.cdk-high-contrast-active .mat-button-base.cdk-program-focused {
    outline: solid 3px
}

.mat-sort-header-container {
    display: flex;
    cursor: pointer;
    align-items: center;
    letter-spacing: normal;
    outline: 0
}

[mat-sort-header].cdk-keyboard-focused .mat-sort-header-container,
[mat-sort-header].cdk-program-focused .mat-sort-header-container {
    border-bottom: solid 1px currentColor
}

.mat-sort-header-disabled .mat-sort-header-container {
    cursor: default
}

.mat-sort-header-content {
    text-align: center;
    display: flex;
    align-items: center
}

.mat-sort-header-position-before {
    flex-direction: row-reverse
}

.mat-sort-header-arrow {
    height: 12px;
    width: 12px;
    min-width: 12px;
    position: relative;
    display: flex;
    opacity: 0
}

.mat-sort-header-arrow,
[dir=rtl] .mat-sort-header-position-before .mat-sort-header-arrow {
    margin: 0 0 0 6px
}

.mat-sort-header-position-before .mat-sort-header-arrow,
[dir=rtl] .mat-sort-header-arrow {
    margin: 0 6px 0 0
}

.mat-sort-header-stem {
    background: currentColor;
    height: 10px;
    width: 2px;
    margin: auto;
    display: flex;
    align-items: center
}

.cdk-high-contrast-active .mat-sort-header-stem {
    width: 0;
    border-left: solid 2px
}

.mat-sort-header-indicator {
    width: 100%;
    height: 2px;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0
}

.mat-sort-header-pointer-middle {
    margin: auto;
    height: 2px;
    width: 2px;
    background: currentColor;
    transform: rotate(45deg)
}

.cdk-high-contrast-active .mat-sort-header-pointer-middle {
    width: 0;
    height: 0;
    border-top: solid 2px;
    border-left: solid 2px
}

.mat-sort-header-pointer-left,
.mat-sort-header-pointer-right {
    background: currentColor;
    width: 6px;
    height: 2px;
    position: absolute;
    top: 0
}

.cdk-high-contrast-active .mat-sort-header-pointer-left,
.cdk-high-contrast-active .mat-sort-header-pointer-right {
    width: 0;
    height: 0;
    border-left: solid 6px;
    border-top: solid 2px
}

.mat-sort-header-pointer-left {
    transform-origin: right;
    left: 0
}

.mat-sort-header-pointer-right {
    transform-origin: left;
    right: 0
}

.mat-datepicker-content {
    display: block;
    border-radius: 4px
}

.mat-datepicker-content .mat-calendar {
    width: 296px;
    height: 354px
}

.mat-datepicker-content .mat-datepicker-close-button {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 8px
}

.ng-animating .mat-datepicker-content .mat-datepicker-close-button {
    display: none
}

.mat-datepicker-content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between
}

.mat-datepicker-content-touch {
    display: block;
    max-height: 80vh;
    overflow: auto;
    margin: -24px
}

.mat-datepicker-content-touch .mat-datepicker-content-container {
    min-height: 312px;
    max-height: 788px;
    min-width: 250px;
    max-width: 750px
}

.mat-datepicker-content-touch .mat-calendar {
    width: 100%;
    height: auto
}

@media all and (orientation: landscape) {
    .mat-datepicker-content-touch .mat-datepicker-content-container {
        width: 64vh;
        height: 80vh
    }
}

@media all and (orientation: portrait) {
    .mat-datepicker-content-touch .mat-datepicker-content-container {
        width: 80vw;
        height: 100vw
    }
    .mat-datepicker-content-touch .mat-datepicker-content-container-with-actions {
        height: 115vw
    }
}

.mat-calendar {
    display: block
}

.mat-calendar-header {
    padding: 8px 8px 0 8px
}

.mat-calendar-content {
    padding: 0 8px 8px 8px;
    outline: none
}

.mat-calendar-controls {
    display: flex;
    margin: 5% calc(33% / 7 - 16px)
}

.mat-calendar-controls .mat-icon-button:hover .mat-button-focus-overlay {
    opacity: .04
}

.mat-calendar-spacer {
    flex: 1 1 auto
}

.mat-calendar-period-button {
    min-width: 0
}

.mat-calendar-arrow {
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top-width: 5px;
    border-top-style: solid;
    margin: 0 0 0 5px;
    vertical-align: middle
}

.mat-calendar-arrow.mat-calendar-invert {
    transform: rotate(180deg)
}

[dir=rtl] .mat-calendar-arrow {
    margin: 0 5px 0 0
}

.mat-calendar-previous-button,
.mat-calendar-next-button {
    position: relative
}

.mat-calendar-previous-button::after,
.mat-calendar-next-button::after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    content: "";
    margin: 15.5px;
    border: 0 solid currentColor;
    border-top-width: 2px
}

[dir=rtl] .mat-calendar-previous-button,
[dir=rtl] .mat-calendar-next-button {
    transform: rotate(180deg)
}

.mat-calendar-previous-button::after {
    border-left-width: 2px;
    transform: translateX(2px) rotate(-45deg)
}

.mat-calendar-next-button::after {
    border-right-width: 2px;
    transform: translateX(-2px) rotate(45deg)
}

.mat-calendar-table {
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%
}

.mat-calendar-table-header th {
    text-align: center;
    padding: 0 0 8px 0
}

.mat-calendar-table-header-divider {
    position: relative;
    height: 1px
}

.mat-calendar-table-header-divider::after {
    content: "";
    position: absolute;
    top: 0;
    left: -8px;
    right: -8px;
    height: 1px
}

.mat-calendar-body {
    min-width: 224px
}

.mat-calendar-body-label {
    height: 0;
    line-height: 0;
    text-align: left;
    padding-left: 4.7142857143%;
    padding-right: 4.7142857143%
}

.mat-calendar-body-cell {
    position: relative;
    height: 0;
    line-height: 0;
    text-align: center;
    outline: none;
    cursor: pointer
}

.mat-calendar-body-cell::before,
.mat-calendar-body-cell::after,
.mat-calendar-body-cell-preview {
    content: "";
    position: absolute;
    top: 5%;
    left: 0;
    z-index: 0;
    box-sizing: border-box;
    height: 90%;
    width: 100%
}

.mat-calendar-body-range-start:not(.mat-calendar-body-in-comparison-range)::before,
.mat-calendar-body-range-start::after,
.mat-calendar-body-comparison-start:not(.mat-calendar-body-comparison-bridge-start)::before,
.mat-calendar-body-comparison-start::after,
.mat-calendar-body-preview-start .mat-calendar-body-cell-preview {
    left: 5%;
    width: 95%;
    border-top-left-radius: 999px;
    border-bottom-left-radius: 999px
}

[dir=rtl] .mat-calendar-body-range-start:not(.mat-calendar-body-in-comparison-range)::before,
[dir=rtl] .mat-calendar-body-range-start::after,
[dir=rtl] .mat-calendar-body-comparison-start:not(.mat-calendar-body-comparison-bridge-start)::before,
[dir=rtl] .mat-calendar-body-comparison-start::after,
[dir=rtl] .mat-calendar-body-preview-start .mat-calendar-body-cell-preview {
    left: 0;
    border-radius: 0;
    border-top-right-radius: 999px;
    border-bottom-right-radius: 999px
}

.mat-calendar-body-range-end:not(.mat-calendar-body-in-comparison-range)::before,
.mat-calendar-body-range-end::after,
.mat-calendar-body-comparison-end:not(.mat-calendar-body-comparison-bridge-end)::before,
.mat-calendar-body-comparison-end::after,
.mat-calendar-body-preview-end .mat-calendar-body-cell-preview {
    width: 95%;
    border-top-right-radius: 999px;
    border-bottom-right-radius: 999px
}

[dir=rtl] .mat-calendar-body-range-end:not(.mat-calendar-body-in-comparison-range)::before,
[dir=rtl] .mat-calendar-body-range-end::after,
[dir=rtl] .mat-calendar-body-comparison-end:not(.mat-calendar-body-comparison-bridge-end)::before,
[dir=rtl] .mat-calendar-body-comparison-end::after,
[dir=rtl] .mat-calendar-body-preview-end .mat-calendar-body-cell-preview {
    left: 5%;
    border-radius: 0;
    border-top-left-radius: 999px;
    border-bottom-left-radius: 999px
}

[dir=rtl] .mat-calendar-body-comparison-bridge-start.mat-calendar-body-range-end::after,
[dir=rtl] .mat-calendar-body-comparison-bridge-end.mat-calendar-body-range-start::after {
    width: 95%;
    border-top-right-radius: 999px;
    border-bottom-right-radius: 999px
}

.mat-calendar-body-comparison-start.mat-calendar-body-range-end::after,
[dir=rtl] .mat-calendar-body-comparison-start.mat-calendar-body-range-end::after,
.mat-calendar-body-comparison-end.mat-calendar-body-range-start::after,
[dir=rtl] .mat-calendar-body-comparison-end.mat-calendar-body-range-start::after {
    width: 90%
}

.mat-calendar-body-in-preview .mat-calendar-body-cell-preview {
    border-top: dashed 1px;
    border-bottom: dashed 1px
}

.mat-calendar-body-preview-start .mat-calendar-body-cell-preview {
    border-left: dashed 1px
}

[dir=rtl] .mat-calendar-body-preview-start .mat-calendar-body-cell-preview {
    border-left: 0;
    border-right: dashed 1px
}

.mat-calendar-body-preview-end .mat-calendar-body-cell-preview {
    border-right: dashed 1px
}

[dir=rtl] .mat-calendar-body-preview-end .mat-calendar-body-cell-preview {
    border-right: 0;
    border-left: dashed 1px
}

.mat-calendar-body-disabled {
    cursor: default
}

.cdk-high-contrast-active .mat-calendar-body-disabled {
    opacity: .5
}

.mat-calendar-body-cell-content {
    top: 5%;
    left: 5%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 90%;
    height: 90%;
    line-height: 1;
    border-width: 1px;
    border-style: solid;
    border-radius: 999px
}

.mat-calendar-body-cell-content.mat-focus-indicator {
    position: absolute
}

.cdk-high-contrast-active .mat-calendar-body-cell-content {
    border: none
}

.mat-datepicker-dialog .mat-dialog-container {
    position: relative;
    overflow: visible
}

.cdk-high-contrast-active .mat-datepicker-popup:not(:empty),
.cdk-high-contrast-active .mat-calendar-body-selected {
    outline: solid 1px
}

.cdk-high-contrast-active .mat-calendar-body-today {
    outline: dotted 1px
}

.cdk-high-contrast-active .cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-high-contrast-active .cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
    outline: dotted 2px
}

[dir=rtl] .mat-calendar-body-label {
    text-align: right
}

@media(hover: none) {
    .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
        background-color: transparent
    }
}

.mat-form-field {
    display: inline-block;
    position: relative;
    text-align: left
}

[dir=rtl] .mat-form-field {
    text-align: right
}

.mat-form-field-wrapper {
    position: relative
}

.mat-form-field-flex {
    display: inline-flex;
    align-items: baseline;
    box-sizing: border-box;
    width: 100%
}

.mat-form-field-prefix,
.mat-form-field-suffix {
    white-space: nowrap;
    flex: none;
    position: relative
}

.mat-form-field-infix {
    display: block;
    position: relative;
    flex: auto;
    min-width: 0;
    width: 180px
}

.cdk-high-contrast-active .mat-form-field-infix {
    border-image: linear-gradient(transparent, transparent)
}

.mat-form-field-label-wrapper {
    position: absolute;
    left: 0;
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none
}

[dir=rtl] .mat-form-field-label-wrapper {
    left: auto;
    right: 0
}

.mat-form-field-label {
    position: absolute;
    left: 0;
    font: inherit;
    pointer-events: none;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    transform-origin: 0 0;
    transition: transform 400ms cubic-bezier(0.25, 0.8, 0.25, 1), color 400ms cubic-bezier(0.25, 0.8, 0.25, 1), width 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    display: none
}

[dir=rtl] .mat-form-field-label {
    transform-origin: 100% 0;
    left: auto;
    right: 0
}

.mat-form-field-empty.mat-form-field-label,
.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: block
}

.mat-form-field-autofill-control:-webkit-autofill+.mat-form-field-label-wrapper .mat-form-field-label {
    display: none
}

.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill+.mat-form-field-label-wrapper .mat-form-field-label {
    display: block;
    transition: none
}

.mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label,
.mat-input-server[placeholder]:not(:placeholder-shown)+.mat-form-field-label-wrapper .mat-form-field-label {
    display: none
}

.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label,
.mat-form-field-can-float .mat-input-server[placeholder]:not(:placeholder-shown)+.mat-form-field-label-wrapper .mat-form-field-label {
    display: block
}

.mat-form-field-label:not(.mat-form-field-empty) {
    transition: none
}

.mat-form-field-underline {
    position: absolute;
    width: 100%;
    pointer-events: none;
    transform: scale3d(1, 1.0001, 1)
}

.mat-form-field-ripple {
    position: absolute;
    left: 0;
    width: 100%;
    transform-origin: 50%;
    transform: scaleX(0.5);
    opacity: 0;
    transition: background-color 300ms cubic-bezier(0.55, 0, 0.55, 0.2)
}

.mat-form-field.mat-focused .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple {
    opacity: 1;
    transform: none;
    transition: transform 300ms cubic-bezier(0.25, 0.8, 0.25, 1), opacity 100ms cubic-bezier(0.25, 0.8, 0.25, 1), background-color 300ms cubic-bezier(0.25, 0.8, 0.25, 1)
}

.mat-form-field-subscript-wrapper {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    overflow: hidden
}

.mat-form-field-subscript-wrapper .mat-icon,
.mat-form-field-label-wrapper .mat-icon {
    width: 1em;
    height: 1em;
    font-size: inherit;
    vertical-align: baseline
}

.mat-form-field-hint-wrapper {
    display: flex
}

.mat-form-field-hint-spacer {
    flex: 1 0 1em
}

.mat-error {
    display: block
}

.mat-form-field-control-wrapper {
    position: relative
}

.mat-form-field-hint-end {
    order: 1
}

.mat-form-field._mat-animation-noopable .mat-form-field-label,
.mat-form-field._mat-animation-noopable .mat-form-field-ripple {
    transition: none
}

.mat-card {
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    display: block;
    position: relative;
    padding: 16px;
    border-radius: 4px
}

._mat-animation-noopable.mat-card {
    transition: none;
    animation: none
}

.mat-card .mat-divider-horizontal {
    position: absolute;
    left: 0;
    width: 100%
}

[dir=rtl] .mat-card .mat-divider-horizontal {
    left: auto;
    right: 0
}

.mat-card .mat-divider-horizontal.mat-divider-inset {
    position: static;
    margin: 0
}

[dir=rtl] .mat-card .mat-divider-horizontal.mat-divider-inset {
    margin-right: 0
}

.cdk-high-contrast-active .mat-card {
    outline: solid 1px
}

.mat-card-actions,
.mat-card-subtitle,
.mat-card-content {
    display: block;
    margin-bottom: 16px
}

.mat-card-title {
    display: block;
    margin-bottom: 8px
}

.mat-card-actions {
    margin-left: -8px;
    margin-right: -8px;
    padding: 8px 0
}

.mat-card-actions-align-end {
    display: flex;
    justify-content: flex-end
}

.mat-card-image {
    width: calc(100% + 32px);
    margin: 0 -16px 16px -16px
}

.mat-card-footer {
    display: block;
    margin: 0 -16px -16px -16px
}

.mat-card-actions .mat-button,
.mat-card-actions .mat-raised-button,
.mat-card-actions .mat-stroked-button {
    margin: 0 8px
}

.mat-card-header {
    display: flex;
    flex-direction: row
}

.mat-card-header .mat-card-title {
    margin-bottom: 12px
}

.mat-card-header-text {
    margin: 0 16px
}

.mat-card-avatar {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    flex-shrink: 0;
    object-fit: cover
}

.mat-card-title-group {
    display: flex;
    justify-content: space-between
}

.mat-card-sm-image {
    width: 80px;
    height: 80px
}

.mat-card-md-image {
    width: 112px;
    height: 112px
}

.mat-card-lg-image {
    width: 152px;
    height: 152px
}

.mat-card-xl-image {
    width: 240px;
    height: 240px;
    margin: -8px
}

.mat-card-title-group>.mat-card-xl-image {
    margin: -8px 0 8px
}

@media(max-width: 599px) {
    .mat-card-title-group {
        margin: 0
    }
    .mat-card-xl-image {
        margin-left: 0;
        margin-right: 0
    }
}

.mat-card>:first-child,
.mat-card-content>:first-child {
    margin-top: 0
}

.mat-card>:last-child:not(.mat-card-footer),
.mat-card-content>:last-child:not(.mat-card-footer) {
    margin-bottom: 0
}

.mat-card-image:first-child {
    margin-top: -16px;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit
}

.mat-card>.mat-card-actions:last-child {
    margin-bottom: -8px;
    padding-bottom: 0
}

.mat-card-actions .mat-button:first-child,
.mat-card-actions .mat-raised-button:first-child,
.mat-card-actions .mat-stroked-button:first-child {
    margin-left: 0;
    margin-right: 0
}

.mat-card-title:not(:first-child),
.mat-card-subtitle:not(:first-child) {
    margin-top: -4px
}

.mat-card-header .mat-card-subtitle:not(:first-child) {
    margin-top: -8px
}

.mat-card>.mat-card-xl-image:first-child {
    margin-top: -8px
}

.mat-card>.mat-card-xl-image:last-child {
    margin-bottom: -8px
}

.spinner-container {
    display: flex;
    justify-content: center;
    padding: 10px;
}