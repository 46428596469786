#maintenance {
    background-color: #fff;
    min-height: 100vh;
}
#maintenance .header {
    background-color: #b11117;
}
#maintenance .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 470px;
}
#maintenance .header img {
    height: 75px;
    padding: 3px 0;
}
#maintenance .body {
    padding: 15px 0;
    color: #170c66;
}
#maintenance .body h1 {
    font-weight: 800;
    font-size: 50px;
    text-transform: uppercase;
}
#maintenance .body p {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    line-height: 30px;
}
#maintenance .body .description p {
    font-size: 26px;
    line-height: unset;
}
#maintenance #maintenance-logo {
    height: 260px;
    padding: 15px 0;
}
#maintenance #maintenance-logo-sub {
    height: 75px;
    padding: 15px 0;
}

@media screen and (max-width: 600px) {
    #maintenance .body h1 {
        font-size: 45px;
    }
    #maintenance .body p {
        font-size: 18px;
    }
	#maintenance .body .description p {
        font-size: 25px;
    }
}

@media screen and (max-width: 426px) {
    #maintenance .header img {
        height: 65px;
    }
    #maintenance .body p {
        font-size: 15px;
        line-height: 22px;
    }
    #maintenance .body .description p {
        font-size: 20px;
    }
    #maintenance #maintenance-logo {
        height: 240px;
    }
    #maintenance #maintenance-logo-sub {
        height: 65px;
    }
}

@media screen and (max-width: 321px) {
    #maintenance .header img {
        height: 60px;
    }
    #maintenance .body h1 {
        font-size: 40px;
    }
    #maintenance .body .description p {
        font-size: 16px;
    }
    #maintenance #maintenance-logo {
        height: 215px;
    }
    #maintenance #maintenance-logo-sub {
        height: 55px;
    }
}