.p-datatable .p-datatable-tbody > tr.p-highlight {
    background: #ffcdcd;
    color: #495057;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight {
    background: #ffcdcd;
    color: #495057;
}

.p-datatable .p-datatable-header {
    background: #b11116;
    color: #ffffff;
    border: 1px solid #e9ecef;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: bold;
    font-size: 12px;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #ffcdcd;
    border-color: #E3F2FD;
    color: #495057;
}

.p-datatable.p-datatable-gridlines .p-paginator-bottom {
    border-width: 0 1px 1px 1px;
    justify-content: flex-end;
    margin: 0 3em;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon,
.p-datatable .p-sortable-column.p-highlight {
    color: #b11116;
}

.p-paginator .p-paginator-current {
    background-color: transparent;
    border: 0 none;
    color: #6c757d;
    min-width: 2.357rem;
    height: 2.357rem;
    margin: 0.143rem;
    padding: 0 0.5rem;
    position: absolute;
    left: 3em;
    font-weight: bold;
}

.p-datatable .p-datatable-tbody > tr {
    outline: none;
}

.p-datatable.p-datatable-sm .p-datatable-thead > tr > th,
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
    text-align: center;
}

.p-datatable-wrapper {
    padding: 0 3em;
}

.cust-margin .mat-icon-button{
    line-height: 1 !important;
    height: 0 !important;
}

.cust-margin .mat-icon-button:disabled,
.cust-margin .mat-icon-button:disabled:hover {
    background-color: transparent !important;
    border: none !important;
}

.p-datatable .p-datatable-tbody > tr,
.p-datatable .p-datatable-thead > tr {
    font-family: Inter;
    font-size: 13px;
}

.p-datatable.p-datatable-sm .p-datatable-thead > tr > th, .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.osp-tbl-wrapper-inv .p-datatable .p-datatable-header {
    background: #cbcbcb;
}

.p-datatable-scrollable-wrapper {
    padding-left: 3em;
    padding-right: 3em;
}