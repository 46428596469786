html {
    font-size: 62.5%;
}
html.no-scroll {
	overflow: hidden;
}
body {
    font-family: "Arial", "Calibri", sans-serif;
	font-size: 16px;
    color: #4A4A4A;
    background: #dadada;
	min-width: 320px;
}
#main-content {
	min-height: calc(100vh - 190px);
}
section {
	width: 100%;
	margin: 0 auto 0;
	padding: 0;
}
.container { 
	max-width: 944px; /* 1040px; */
	width: 95%;
	margin: 0 auto;
}
section.section_box { 
	padding: 80px 0;
}
section.section_box:last-of-type {
	padding: 80px 0 100px;
}
.bg1 { 
	background: #FFF;
}
.btn {
	border-radius: 5px;
	text-align: center;
	box-sizing: border-box;
	cursor: pointer;
	transition: all ease .3s;
	min-width: 144px;
	padding: 13px 11px;
	border: 1px solid #E0E0E0;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0.04em;
}
.btn:focus {
	outline: 0;
}
.btn1 {
	background-color: #FFF;
	color: #B11116;
}
.btn1:hover {
	background-color: #8B0F13;
    color: #FFF;
	border-color: #8B0F13;
}
.btn2 {
	background-color: #B11116;
	color: #FFF;
	border-color: #B11116;
}
.btn2:hover {
	background-color: #8B0F13;
	border-color: #8B0F13;
}
.btn3 {
	background-color: #1B1464;
	color: #FFF;
	border-color: #1B1464;
}
.btn3:hover {
	background-color: #110d36;
	border-color: #110d36;
}
.btn4 {
	background-color: #E0E0E0;
	color: #000;
	border-color: #E0E0E0;
}
.btn4:hover {
	background-color: #a3a3a3;
	border-color: #a3a3a3;
}
.btn5 {
	border: 1px solid #B11116;
	background-color: transparent;
	color: #B11116;
}
.btn5:hover {
	background-color: #B11116;
	color: #FFF;
	border-color: #B11116;
}
.btn6 {
	border: 1px solid #1B1464;
	background-color: transparent;
	color: #1B1464;
}
.btn6:hover {
	background-color: #1B1464;
	color: #FFF;
	border-color: #1B1464;
}
.top-border::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 5px;
    top: 0;
    left: 0;
    border-radius: 3px;
}
.top-border.border1::before {
    background-color: #B11116;
}
.top-border.border2::before {
    background-color: #1B1464;
}
.top-border.border3::before {
    background-color: #219653;
}
.top-border.border4::before {
    background: linear-gradient(270deg, #B11116 0%, #D5B527 100%);
}

@media screen and (max-width: 767px) {
	section.section_box {
		padding: 40px 0;
	}
	section.section_box:last-of-type {
		padding: 40px 0 60px;
	}
}

@media screen and (max-width: 550px) {
	#main-content {
		min-height: calc(100vh - 215.67px);
	}
}

.overlay-header {
    color:#1B1464;
}

.d-none{
    display: none;
}

.mtn-title {
    border-bottom: #B11116 3px solid;
	padding: 5px 5px;
	margin-bottom: 10px;
	font-size: 15px;
	font-family: Arial, Helvetica, sans-serif;
	color: #1B1464;
}

.mtn-title > p {
	font-weight: 700;
}

/****[Scroll Bar Temp]****/

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
::-webkit-scrollbar-track {
	border-radius: 10px;
    background: rgba(0,0,0,0.2);
}
::-webkit-scrollbar-thumb{
	border-radius: 10px;
    background: rgba(0,0,0,0.3);
}
::-webkit-scrollbar-thumb:hover{
    background: rgba(0,0,0,0.4);
}
::-webkit-scrollbar-thumb:active{
    background: rgba(0,0,0,.9);
}