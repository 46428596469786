@import '../fonts/css/Roboto.css';
@import '../fonts/css/Proxima_Nova.css';
@import '../fonts/css/Montserrat.css';
@import '../fonts/css/Inter.css';
@import '../fonts/css/Material_Icons.css';
@import '../fonts/css/Open_Sans.css';

.roboto {
    font-family: "Roboto", "Arial", "Calibri", sans-serif;
}
.proxima {
    font-family: "ProximaNova", "Arial", "Calibri", sans-serif;
}
.montserrat {
    font-family: "Montserrat", "Arial", "Calibri", sans-serif;
}
.inter {
    font-family: "Inter", "Arial", "Calibri", sans-serif;
}
.material {
    font-family: "Material Icons", "Arial", "Calibri", sans-serif;
}
.openSans {
    font-family: "Open Sans", "Arial", "Calibri", sans-serif;
}
.arial {
    font-family: "Arial", "Calibri", sans-serif;
}